.hero-container-main-wrap {
  // height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  /* overflow: hidden; */
  .hero-item-right-content.image-bouns-animation {
    position: relative;
    top: 80px;
  }
}
.footer-single-item-content > a > img {
  height: 55px;
}
.hero-item-left-content > p {
  /* width: 80%; */
  margin: 25px 50px;
}
.hero-footer-image-wrap-fieldx > .row {
  justify-content: center;
  height: 100%;
}

.hero-footer-image-wrap-fieldx {
  position: absolute;
}

.hero-footer-image-wrap-fieldx {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 100%;
}
.fieldx-hero-inner-wrap > .container {
  height: 100%;
}
.fieldx-hero-inner-wrap > .container > * {
  height: 90%;
}
.fieldx-hero-inner-wrap > .container > * > .hero-footer-image-wrap-fieldx {
  height: 100%;
}
.fieldx-hero-inner-wrap > .container > * > * > * > .my-auto.col-lg-8 {
  height: 100%;
  position: relative;
}
.fieldx-hero-inner-wrap > .container > * > * > * > * {
  height: 100%;
}
.fieldx-hero-inner-wrap > .container > * > * > * > * > * > img {
  height: auto;
  display: flex;
  position: absolute;
  bottom: -460px;
  z-index: 999;
  width: 100%;
}
.hero-footer-image-wrap-fieldx- > * {
  justify-content: center;
  margin-top: 30px;
}
.hero-top-content-wrap-fieldx {
  padding-top: 50px;
}
.service-single-item > img {
  margin-bottom: 8px;
  background: #009b9b1a;
  padding: 10px;
  border-radius: 5px;
  width: 44px;
}

li.navbar-salesx-right-single-btn {
  border-radius: 10px;
  background: #009b9b;
}

li.navbar-salesx-right-single-btn a {
  color: #fff !important;
}
button.btn.feature-left-btn.hero {
  background: #0180f5;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  padding: 8px 20px;
  padding-bottom: 10px;
}
.hero-brand-icon-right {
  width: 48vw;
  position: absolute;
  right: 0;
  top: -78px;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  opacity: 0.3;
}
.hero-brand-icon-right img {
  width: 100%;
}
.hero-inner-wrap {
  position: relative;
}
.hero-item-right-content img {
  width: 100%;
  margin: 0 auto !important;
  display: block;
}
.image-feature-mobile img {
  height: 100%;
}
.feature-main-area {
  position: relative;
  z-index: 9;
  padding-top: 50px;
  /* margin-top: 350px; */
}
.feature-inner-single-item span {
  width: 50px;
  height: 50px;
  background: #0180f520;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  color: #0180f5;
  margin-bottom: 20px;
  border-radius: 10px;
}
.feature-inner-single-item.palning h4 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}
.feature-inner-single-item.palning p {
  font-size: 18px;
  margin-bottom: 25px;
}
.palning-inner-area-wrap.live-share-inner {
  background: #f8f9fb;
  padding: 30px 60px;
  border-radius: 40px;
}
button.btn.feature-left-btn.palning {
  border: 2px solid #009b9b;
  border-radius: 10px;
  font-weight: 600;
  color: #009b9b;
}

button.btn.feature-left-btn a {
  color: #009b9b;
  text-decoration: none;
}

.feature-inner-single-item.LiveShare h4 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}

.feature-inner-single-item.LiveShare p {
  margin-bottom: 25px;
}
.palning-main-area {
  padding-top: 50px;
}

.industries-single-item {
  width: 100%;
  height: 250px;
  background: #ddd;
}

.industries-main-wrap .slick-slide {
  padding: 10px;

  text-align: center;
}

.industries-single-item {
  width: 100%;
  height: 150px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 20px 0px #dddddd78;
  border-radius: 10px;
}
.industries-single-item > * > * > img {
  margin: auto;
  display: inline-block;
  margin-bottom: 15px;
}
.industries-single-item-inner {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.industries-single-item-inner > * > * > * {
  text-align: center;
  margin: auto;
  margin-bottom: 12px;
}
.footer-single-item-content > ul {
  padding-left: 0;
}

.footer-single-item-content ul li {
  list-style: none;
}
.review-area-hero span {
  color: #ffd001;
}

.review-area-hero {
  margin-top: 15px;
  margin-bottom: 40px;
}

.review-area-hero > span > span {
  color: #000;
}

.review-area-hero span {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

/* =========================================  */
.hero-top-content-wrap-fieldx > .row {
  justify-content: center;
  text-align: center;
}

.fieldx-hero-inner-wrap {
  position: relative;
  /*  */
  width: 100%;
  margin-top: 30px;
  border-radius: 25px;
}
.hero-inner-wrap {
  width: 100%;
  position: relative;
  background: #f8f9fb;
  border-radius: 40px;
}
.feature-inner-single-item.image-feature-mobile.image-bouns-animation.feature-middle-app-pic
  img {
  width: 100%;
  height: auto;
}
/* .feature-inner-single-item {
  margin-bottom: 50px;
} */
.service-inner-wrap {
  padding-top: 80px;
}
.industries-main-wrap {
  padding: 50px 0;
}
.service-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
  justify-content: center;
  width: 100%;
  text-align: center;
  /* background: #f8f8f8; */
  padding: 50px 20px;
  border-radius: 10px;
}

.service-main-area {
  padding: 70px 0;
}
.service-area-top-content {
  text-align: center;
}
.service-area-top-content h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}
.service-area-top-content p {
  font-size: 18px;
  margin-bottom: 25px;
}
.service-single-data h1 {
  font-weight: 700;
}
/* =========================================  */
@media only screen and (min-width: 1600px) {
  .fieldx-hero-inner-wrap > .container > * > * > * > * > * > img {
    height: auto;
    display: flex;
    position: absolute;
    bottom: -300px;
    z-index: 999;
    width: 100%;
  }
}
// @media only screen and (min-width: 1499px) and (max-width: 1600px) {
//   .service-inner-wrap {
//     padding-top: 460px !important;
//   }
// }
@media only screen and (max-width: 1499px) {
  .fieldx-hero-inner-wrap > .container > * > * > * > * > * > img {
    height: auto;
    display: flex;
    position: absolute;
    bottom: -300px;
    z-index: 999;
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .fieldx-hero-inner-wrap > .container > * > * > * > * > * > img {
    height: auto;
    display: flex;
    position: absolute;
    bottom: -460px;
    z-index: 999;
    width: 100%;
  }
  .hero-container-main-wrap {
    min-height: 80vh;
  }
  .hero-item-right-content img {
    width: 100%;
    height: auto;
  }

  .image-feature-mobile img {
    width: 100%;
    height: auto;
  }

  .call-to-action-left {
    width: 40%;
  }
  .call-to-action-input-wrap button {
    width: 45%;
  }
  .call-to-action-input-wrap input {
    width: 53%;
  }
}
.footer-single-item-content > p {
  color: #fff;
}
@media only screen and (max-width: 992px) {
  .hero-inner-wrap > .row {
    flex-direction: column-reverse;
    text-align: center;
  }
  .hero-item-left-content > p {
    width: 100%;
    margin: 15px 0;
  }
  .feature-inner-area-wrap > .row {
    flex-direction: column-reverse;
  }
  .image-feature-mobile {
    text-align: center;
    margin-bottom: 30px;
  }
  .image-feature-mobile img {
    width: unset;
    height: 450px;
    margin: auto;
  }
  .palning-inner-area-wrap.planing-page > .row {
    text-align: center;
    flex-direction: column-reverse;
  }
  .feature-inner-area-wrap {
    text-align: center;
  }
  .feature-inner-single-item span {
    margin: 20px auto;
  }
  .call-to-action-area {
    flex-wrap: wrap;
  }
  .call-to-action-input-wrap {
    flex-wrap: wrap;
  }
  .call-to-action-input-wrap input {
    width: 100%;
  }
  .call-to-action-input-wrap button {
    width: 100%;
  }
  .call-to-action-left h2 {
    font-size: 20px;
  }
  .feature-inner-single-item.LiveShare {
    text-align: center;
  }
  .hero-brand-icon-right {
    display: none;
  }
  .fieldx-hero-inner-wrap > .container > * > * > * > * > * > img {
    height: auto;
    display: flex;
    position: absolute;
    bottom: -130px;
    z-index: 999;
    width: 100%;
  }
  .col-lg-4.col-md-6.col-6.footer-bottom-item-hide-area {
    display: none;
  }
  .footer-single-item-content > * > img {
    color: #fff;
    height: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .call-to-action-left {
    width: 100%;
  }
  .call-to-action-right {
    width: 70%;
    margin: auto;
  }
  .image-feature-mobile img {
    width: unset;
    height: 300px;
    margin: auto;
  }
  .hero-container-main-wrap {
    height: 100vh;
  }
  .industries-single-item {
    height: 140px;
  }
  .industries-single-item-inner > * > * > img {
    height: 50px;
  }
  .industries-single-item-inner > * > h5 {
    font-size: 16px;
  }
  .palning-main-area.live-share-area {
    padding-bottom: 40px;
  }
  .header-logo a {
    display: inline-block;
    height: 40px !important;
  }
  .review-area-hero span {
    justify-content: center;
  }
  .service-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(29%, 1fr));
    justify-content: center;
    width: 100%;
    text-align: center;
    // background: #f8f8f8;
    padding: 50px 20px;
    border-radius: 10px;
    gap: 4vw;
  }
}
@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 992px) {
}
@media only screen and (max-width: 992px) {
}
/* ================================================================ */
.image-bouns-animation img {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.image-bouns-animation img {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

/* #animated-example {
  width: 20px;
  height: 20px;
  background-color: red;
  position: relative;
  top: 100px;
  left: 100px;
  border-radius: 50%;
} */

@-webkit-keyframes bounce {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
  }
}

@keyframes bounce {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.btn--swap {
  overflow: hidden;
}
.btn--swap div,
.btn--swap span {
  position: relative;
}

.btn--swap span {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  display: block;
  transform: translateX(var(--transform-translate-x))
    translateY(var(--transform-translate-y)) rotate(var(--transform-rotate))
    skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y))
    scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap;
}

.btn--swap span:nth-of-type(2) {
  color: #000000;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, 250%);
}
.btn--swap:hover span:nth-of-type(2) {
  --text-opacity: 1;
  /* color: #fff; */

  transform: translate(-50%);
}
.btn--swap:hover .hero-button-area-left-2 {
  --text-opacity: 1;
  color: #fff;

  transform: translate(-50%);
}
.btn--swap:hover span:first-of-type {
  transform: translateY(-250%);
}
button.btn.feature-left-btn.hero:hover {
  background: #023d98 !important;
}
span.playstore {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #000;
}
.hero-main-area-button {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
}
button.btn.feature-left-btn.hero.btn--swap.play-store-button {
  background: #fff;
  border: 2px solid #000;
  color: #009b9b;
}
button.btn.feature-left-btn.hero.btn--swap.play-store-button:hover {
  border: 2px solid #000;
  color: #000 !important;
}
button.btn.feature-left-btn.hero.btn--swap a {
  color: #fff;
}
.hero-button-area-left-1 a {
  color: #023d98 !important;
}
button.btn.feature-left-btn.hero {
  background: #ffffff !important;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  padding: 8px 20px;
  padding-bottom: 10px;
  border: 2px solid #023d98;
}

/* ================================================================ */
.contact-form-main-area-wrap {
  padding: 70px 0;
  .contact-form-inner-area-wrap {
    label {
      color: #686970;
    }
    *::placeholder {
      color: #bfbfb6;
      position: relative;
      font-size: 13px;
      top: -1px;
    }
    h1 {
      font-weight: 700;
      margin-bottom: 50px;
    }
    button {
      margin-top: 20px;
    }
  }
}
thead.mobile-view-table-date {
  display: none;
  tr th {
    font-size: 13px;
  }
}
@media screen and (max-width: 1500px) {
  .service-main-area {
    padding: 70px 0;
    // padding-top: 335px;
  }
}
@media screen and (max-width: 1030px) {
  .service-main-area {
    padding: 70px 0;
    // padding-top: 220px;
  }
}

@media screen and (max-width: 992px) {
  h1 {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .contact-form-inner-area-wrap > * {
    flex-direction: column-reverse;
  }
  .contact-form-main-area-wrap {
    padding: 40px 0;
  }
  .feature-inner-single-item.merchx-about-page-left {
    margin-bottom: 40px;
  }
  .pricing-items-main-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    .pricing-content-area-wrap {
      width: 33%;
      &:nth-child(1) {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin-bottom: 40px;
      }
    }
  }
  .pricing-top-area-title h4 {
    font-size: 30px;
    font-weight: 700;
  }
}
@media screen and (max-width: 778px) {
  thead.mobile-view-table-date {
    display: table-header-group;
    th {
      padding-bottom: 15px;
    }
  }
  .pricing-items-main-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    .pricing-content-area-wrap {
      width: 100%;
      margin-bottom: 30px;
      &:nth-child(1) {
        width: 100%;
        text-align: center;
        justify-content: center;
        margin-bottom: 40px;
      }
    }
    tbody.mobile-view-table-date tr > td:first-child {
      display: none;
    }
  }
  .service-main-area {
    padding: 70px 0;
    // padding-top: 180px;
  }
}
