/* ============================ */
body {
  font-family: "DM Sans", sans-serif;
}
/* ============================ */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header.header-main-area {
  position: sticky;
  top: 0;
  z-index: 99999;
  background: #fff !important;
  box-shadow: 0px 0px 10px 0px rgb(221 221 221 / 58%);
  padding: 10px 0;
}
.hero-item-left-content h3 {
  font-size: 48px;
  font-weight: 700;
}

/* .service-inner-wrap {
  padding-top: 500px;
} */
.palning-main-area.live-share-area.merchx-about-page {
  padding: 70px 0;
}
.feature-inner-single-item.merchx-about-page-left h4 {
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 50px;
}
.merchx-about-right-single-item {
  padding-left: 30px;
  margin-bottom: 50px;
  h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
    position: relative;
    color: #009b9b;

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 8px;
      background: #009b9b;
      top: 10px;
      left: -30px;
      border-radius: 50%;
    }
  }
  p {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
  }
}
.feature-inner-single-item.merchx-about-page-left svg {
  width: 70%;
  margin: 0 auto;
  display: block;
}

.palning-inner-area-wrap.live-share-inner.core-our-value-inner-wrap {
  text-align: center;
  margin-bottom: 50px;
  padding: 90px 40px;
  .our-values-title {
    margin-bottom: 50px;
    h4 {
      font-size: 32px;
      font-weight: 700;
    }
  }
}
.feature-inner-single-item.our-core-values-single {
  padding: 0px 30px;
  img {
    display: inline-block;
    margin-bottom: 30px;
  }
  h6 {
    font-size: 24px;
    font-weight: 700;
  }
}
.pricing-items-main-wrap {
  display: grid;
  align-items: center;
  // justify-content: space-between;
  min-height: 250px;
  grid-template-columns: auto auto auto auto;
  .pricing-content-area-wrap {
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;

    &:nth-child(1) {
      text-align: left;
      justify-content: left;
    }
    &.recommended {
      background: #009b9b;
      color: #fff;
      min-height: 250px;
      border-radius: 10px;
      padding: 60px 0;
    }
    .pricing-content-area-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      gap: 40px;
      a {
        color: #009b9b;
        font-weight: 700;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        background: #f2f6fd;
        border-radius: 25px;
        padding: 10px 20px;
        display: inline-block;
        margin-top: 15px;
      }
    }
  }
}
.pricing-inner-area-wrap {
  padding: 50px 0;
}
.pricing-top-area-title {
  text-align: center;
  margin-bottom: 80px;

  h4 {
    font-size: 60px;
    font-weight: 700;
  }
}
.pricing-items-main-wrap.pricing-table {
  margin-top: 30px;
  display: block;
  tr {
    text-align: center;
    td {
      padding: 15px 30px;
      width: 22%;
      &:first-child {
        width: 34%;
        text-align: left;
      }
    }
    td {
      padding: 15px 30px;
      &:first-child {
        text-align: left;
      }
    }
  }
}
button.contact-form-submit-btn {
  border-radius: 10px;
  background: #009b9b;
  border: none;
  color: #fff;
  padding: 10px 25px;
}
