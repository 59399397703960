.header-logo a {
  display: inline-block;
  height: 55px !important;
}

.header-logo a img {
  height: 100%;
}
.bg-black {
  --bs-bg-opacity: unset;
  background-color: unset;
}

/* header.header-main-area > * {
  height: 80px;
}
header.header-main-area > * > * {
    height: 80px;
}
header.header-main-area > * > * > * {
    height: 80px;
}
header.header-main-area > * > * > * > * {
    height: 80px;
}
header.header-main-area > * > * > * > * > * {
    height: 80px;
}
header.header-main-area > * > * > * > * > * > * {
    height: 80px;
} */
.service-inner-wrap h3 {
  /* font-family: Born Ready Slanted; */
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
  margin-left: 6px;
}
